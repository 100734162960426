const MiddleWestTNCounties = [
    {
      name: "Bedford",
      government: "https://www.bedfordcountytn.gov/",
      demographics: "https://data.census.gov/profile/Bedford_County,_Tennessee?g=050XX00US47003",
      cities: ["Bell Buckle", "Normandy", "Shelbyville", "Unionville", "Wartrace"]
    },
    {
      name: "Cannon",
      government: "https://cannoncountytn.gov/",
      demographics: "https://data.census.gov/profile/Cannon_County,_Tennessee?g=050XX00US47015",
      cities: ["Auburntown", "Woodbury"]
    },
    {
      name: "Cheatham",
      government: "https://www.cheathamcountytn.gov/",
      demographics: "https://data.census.gov/profile/Cheatham_County,_Tennessee?g=050XX00US47021",
      cities: ["Ashland City", "Kingston Springs", "Pegram", "Pleasant View"]
    },
    {
      name: "Coffee",
      government: "https://coffeecountytn.gov/27/Government",
      demographics: "https://data.census.gov/profile/Coffee_County,_Tennessee?g=050XX00US47031",
      cities: ["Hillsboro", "Lakewood Park", "Manchester", "New Union", "Tullahoma"]
    },
    {
      name: "Davidson",
      government: "https://www.nashville.gov/",
      demographics: "https://data.census.gov/profile/Davidson_County,_Tennessee?g=050XX00US47037",
      cities: [
        "Belle Meade",
        "Berry Hill",
        "Forest Hills",
        "Goodlettsville",
        "Nashville-Davidson",
        "Oak Hill",
        "Ridgetop"
      ]
    },
    {
      name: "Dickson",
      government: "https://dicksoncountytn.gov/",
      demographics: "https://data.census.gov/profile/Dickson_County,_Tennessee?g=050XX00US47043",
      cities: ["Charlotte", "Dickson", "Slayden", "Vanleer", "White Bluff"]
    },
    {
      name: "Franklin",
      government: "https://franklincotn.us/",
      demographics: "https://data.census.gov/profile/Franklin_County,_Tennessee?g=050XX00US47051",
      cities: [
        "Belvidere",
        "Cowan",
        "Decherd",
        "Estill Springs",
        "Huntland",
        "Monteagle",
        "Sewanee",
        "Sherwood",
        "Winchester"
      ]
    },
    {
      name: "Giles",
      government: "https://gilescountytn.gov/",
      demographics: "https://data.census.gov/profile/Giles_County,_Tennessee?g=050XX00US47055",
      cities: ["Ardmore", "Elkton", "Frankewing", "Lynnville", "Minor Hill", "Prospect", "Pulaski"]
    },
    {
      name: "Hickman",
      government: "https://hickmancountytn.gov/",
      demographics: "https://data.census.gov/profile/Hickman_County,_Tennessee?g=050XX00US47081",
      cities: ["Bon Aqua Junction", "Centerville", "Lyles", "Wrigley"]
    },
    {
      name: "Houston",
      government: "https://www.explorehoustoncountytn.com/",
      demographics: "https://data.census.gov/profile/Houston_County,_Tennessee?g=050XX00US47083",
      cities: ["Erin", "Tennessee Ridge"]
    },
    {
      name: "Humphreys",
      government: "https://www.humphreystn.com/",
      demographics: "https://data.census.gov/profile/Humphreys_County,_Tennessee?g=050XX00US47085",
      cities: ["McEwen", "New Johnsonville", "Waverly"]
    },
    {
      name: "Jackson",
      government: "https://www.jacksoncotn.com/index.php",
      demographics: "https://data.census.gov/profile/Jackson_County,_Tennessee?g=050XX00US47087",
      cities: ["Dodson Branch", "Gainesboro"]
    },
    {
      name: "Lawrence",
      government: "https://lawrencecountytn.gov/",
      demographics: "https://data.census.gov/profile/Lawrence_County,_Tennessee?g=050XX00US47099",
      cities: [
        "Ethridge",
        "Iron City",
        "Lawrenceburg",
        "Leoma",
        "Loretto",
        "Saint Joseph",
        "Summertown",
        "Westpoint"
      ]
    },
    {
      name: "Lewis",
      government: "https://www.lewiscountytn.com/",
      demographics: "https://data.census.gov/profile/Lewis_County,_Tennessee?g=050XX00US47101",
      cities: ["Hohenwald"]
    },
    {
      name: "Lincoln",
      government: "https://lincolncountytn.gov/",
      demographics: "https://data.census.gov/profile/Lincoln_County,_Tennessee?g=050XX00US47103",
      cities: ["Ardmore", "Blanche", "Delrose", "Fayetteville", "Flintville", "Park City", "Petersburg", "Taft"]
    },
    {
      name: "Macon",
      government: "https://maconcountytn.gov/",
      demographics: "https://data.census.gov/profile/Macon_County,_Tennessee?g=050XX00US47111",
      cities: ["Lafayette", "Red Boiling Springs"]
    },
    {
      name: "Marshall",
      government: "https://www.marshallcountytn.com/",
      demographics: "https://data.census.gov/profile/Marshall_County,_Tennessee?g=050XX00US47117",
      cities: ["Chapel Hill", "Cornersville", "Lewisburg", "Petersburg"]
    },
    {
      name: "Maury",
      government: "https://www.maurycounty-tn.gov/",
      demographics: "https://data.census.gov/profile/Maury_County,_Tennessee?g=050XX00US47119",
      cities: ["Columbia", "Mount Pleasant", "Spring Hill"]
    },
    {
      name: "Montgomery",
      government: "https://mcgtn.org/",
      demographics: "https://data.census.gov/profile/Montgomery_County,_Tennessee?g=050XX00US47125",
      cities: ["Clarksville"]
    },
    {
      name: "Moore",
      government: "https://metromoorecounty.org/",
      demographics: "https://data.census.gov/profile/Moore_County,_Tennessee?g=050XX00US47127",
      cities: ["Lynchburg"]
    },
    {
      name: "Perry",
      government: "https://gov.perrycountytn.com/",
      demographics: "https://data.census.gov/profile/Perry_County,_Tennessee?g=050XX00US47135",
      cities: ["Linden", "Lobelville"]
    },
    {
      name: "Robertson",
      government: "https://robertsoncountytn.gov/",
      demographics: "https://data.census.gov/profile/Robertson_County,_Tennessee?g=050XX00US47147",
      cities: [
        "Adams",
        "Cedar Hill",
        "Coopertown",
        "Cross Plains",
        "Greenbrier",
        "Millersville",
        "Orlinda",
        "Ridgetop",
        "Springfield",
        "White House"
      ]
    },
    {
      name: "Rutherford",
      government: "https://rutherfordcountytn.gov/",
      demographics: "https://data.census.gov/profile/Rutherford_County,_Tennessee?g=050XX00US47149",
      cities: [
        "Christiana",
        "Eagleville",
        "LaVergne",
        "Murfreesboro",
        "Rockvale",
        "Smyrna",
        "Walterhill"
      ]
    },
    {
      name: "Smith",
      government: "https://smithcotn.com/",
      demographics: "https://data.census.gov/profile/Smith_County,_Tennessee?g=050XX00US47159",
      cities: ["Carthage", "Gordonsville", "Hickman", "South Carthage"]
    },
    {
      name: "Stewart",
      government: "https://www.stewartcogov.com/",
      demographics: "https://data.census.gov/profile/Stewart_County,_Tennessee?g=050XX00US47161",
      cities: ["Big Rock", "Cumberland City", "Dover"]
    },
    {
      name: "Sumner",
      government: "https://sumnercountytn.gov/",
      demographics: "https://data.census.gov/profile/Sumner_County,_Tennessee?g=050XX00US47165",
      cities: [
        "Bethpage",
        "Bransford",
        "Castalian Springs",
        "Cottontown",
        "Fairfield",
        "Gallatin",
        "Goodlettsville",
        "Graball",
        "Hendersonville",
        "Millersville",
        "Mitchelville",
        "New Deal",
        "Oak Grove",
        "Portland",
        "Shackle Island",
        "Westmoreland",
        "White House"
      ]
    },
    {
      name: "Trousdale",
      government: "https://www.trousdalecountytn.gov/",
      demographics: "https://data.census.gov/profile/Trousdale_County,_Tennessee?g=050XX00US47169",
      cities: ["Hartsville"]
    },
    {
      name: "Wayne",
      government: "https://www.waynecountytn.org/",
      demographics: "https://data.census.gov/profile/Wayne_County,_Tennessee?g=050XX00US47181",
      cities: ["Clifton", "Collinwood", "Waynesboro"]
    },
    {
      name: "Williamson",
      government: "https://www.williamsoncounty-tn.gov/",
      demographics: "https://data.census.gov/profile/Williamson_County,_Tennessee?g=050XX00US47187",
      cities: ["Brentwood", "Fairview", "Franklin", "Nolensville", "Spring Hill", "Thompson's Station"]
    },
    {
      name: "Wilson",
      government: "https://wilsoncountytn.gov/",
      demographics: "https://data.census.gov/profile/Wilson_County,_Tennessee?g=050XX00US47189",
      cities: [
        "Gladeville",
        "Green Hill",
        "GreenVale",
        "Lebanon",
        "Mount Juliet",
        "Norene",
        "Rural Hill",
        "Statesville",
        "Tukers Crossroads",
        "Watertown"
      ]
    }
  ];
  
  export default MiddleWestTNCounties;
  